.row-spacing {
    margin-bottom: 2rem;
}

.background {
    background-color: rgb(255, 255, 255);
    height: 100%;
}

.summary-text {
    color: white;
    text-align: center;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: rgb(242, 242, 242);
    border-radius: .3rem;
}

.feature-card {
    background-color: rgb(255, 255, 255);
    border: none;
    width: 100%;
    text-align: center;
}

.summary-button {
    width: 10rem;
}

.card-padding {
    padding-bottom: 2rem;
}