.description {
    margin-left: 1rem;
    margin-bottom: 0.25rem;
    white-space: pre-wrap;
}

.title-margin {
    margin-top: 1rem;
}

.code-margin {
    margin-top: 0.5rem;
}