.app-header {
  background-color: rgb(255, 255, 255);
  height: 4rem;
  border-style: none none solid none;
  border-color:rgb(230, 230, 230);
}

.log-out {
  margin-right: 2rem;
}

.menu-dropdown-margin {
  margin-left: 2rem;
}

.button-color {
  background-color: rgb(54, 111, 255);
  border-color: rgb(54, 111, 255);
}

.theme-color {
  color:rgb(54, 111, 255);
}

.credit-row {
  text-align: center;
}

.credits {
  font-size: x-small;
}

.main-card {
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border: 0rem;
}

.no-underline-link {
    text-decoration: none;
}

.menu-margin {
    margin-left: 1rem;
}

.header-text {
    font-size: small;
}

.header-text:hover:after {
    content: "";
    display: block;
    width: 70%;
    border-bottom: 3px solid;
    border-radius: 25px;
    margin: 0 auto;
}

.logo-text, .logo-text:hover {
    font-weight: bold;
    text-decoration: none;
    color: rgb(54, 111, 255);
}


.tooltip {
  font-size: 14px;
}

.tooltip-inner {
  font-weight: 400;
}