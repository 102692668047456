.note-box-card {
    width: 12rem;
    margin: 1rem;
    height: 10rem;
    transition: transform 250ms;
}

.note-box-card:hover {
    transform: translateY(-10px);
}

.bold-text {
    font-weight: 650;
}

.title-row {
    height: 4.8rem;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}