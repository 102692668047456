.sort-select {
    width: 15rem;
    background-color: rgb(245, 245, 245);
    border: none;
}

.plus-icon {
    height: 4rem;
    width: 4rem;
    -webkit-filter: drop-shadow(1px 1px 1px #222);
    filter: drop-shadow(1px 1px 1px #222);
}

.plus-icon-card {
    align-items: center;
    justify-content: center;
}