.create-button {
    width: 10rem;
}

.create-field {
    background-color: rgb(245, 245, 245);
    border: none;
}

.button-padding {
    margin-right: 2rem;
}

.analyze-section {
    margin-bottom: 2rem;
}

.col-centered {
    display: flex;
    flex-direction: columns;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.insight-padding {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}